.root {
  height: 100vh;
  overflow-y: scroll;
  background-color: black;
  color: white;
  padding: 16px;
}

.root::-webkit-scrollbar {
  display: none;
}

.drawer {
  width: 300px;
}

.title {
  display: block;
  margin-bottom: 32px;
  color: white;
}

.versionwrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.version {
  color: rgb(108, 108, 108);
  font-size: 0.8em;
  transform: translateY(-1px);
}
