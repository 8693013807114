.input {
  border: none;
  font-size: unset;
  outline: none;
  background: white;
  border-radius: var(--radius);
  padding: 8px;
  transition: all 250ms;
  margin-bottom: 32px;
  width: 100%;
}

.popper {
  z-index: 10000;
}

.alert {
  display: block;
  text-align: center;
  padding: 16px 0px;
}

.results {
  padding: 8px;
  padding-top: 16px;
  max-height: 50vh;
  overflow-y: scroll;
  border-radius: var(--radius) !important;
  overflow: auto;
}

.result {
  padding: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: inherit;
  text-decoration: none;
  width: 100%;
}

.result:hover {
  background: var(--hover);
}

.resultimage {
  height: 48px;
  width: 48px;
  border-radius: 6px;
  margin-right: 8px;
}

.section {
  position: relative;
  text-align: center;
  font-weight: bold;
  margin-bottom: 16px;
}

.section::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 50%;
  width: 40px;
  height: 1px;
  background-color: var(--primary);
}

.section::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 50%;
  width: 40px;
  height: 1px;
  background-color: var(--primary);
}
